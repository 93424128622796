import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBYlgy2vjU3xsBeCQylW_p1J3ru0vGH0Po",
  authDomain: "insurance-agents-dev.firebaseapp.com",
  projectId: "insurance-agents-dev",
  storageBucket: "insurance-agents-dev.appspot.com",
  messagingSenderId: "473090179609",
  appId: "1:473090179609:web:72fe11e8c6e59d08c9a088",
  measurementId: "G-LRWQT0LGK9",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
