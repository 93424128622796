import { useQuery } from "react-query";
import { auth } from "./firebase";
import { Method } from "./types";
import { QueryError, getQueryErrorCode } from "./errors";

export interface UseQueryParams {
  pathname: string;
  method?: Method;
  server?: string;
  body?: Record<string, unknown>;
  key?: string | Array<unknown>;
  queryOptions?: Record<string, unknown>;
}

const createRequestHeaders = async () => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (auth.currentUser) {
    const idToken = await auth.currentUser.getIdToken();
    headers["Authorization"] = `Bearer ${idToken}`;
  }
  return headers;
};

const useHttpQuery = <T>(params: UseQueryParams) => {
  const {
    pathname,
    method = "GET",
    server = "https://api-hhh3eu72sa-uc.a.run.app",
    body,
    key,
    queryOptions = {},
  } = params;
  const url = new URL(pathname, server).toString();
  const queryKey = key ? key : [`${method} ${url}`];
  return useQuery<T, QueryError>(
    queryKey,
    async () => {
      try {
        const response = await fetch(url, {
          method,
          body: body ? JSON.stringify(body) : null,
          headers: await createRequestHeaders(),
        });
        if (response.ok) {
          return response.json() as T;
        }
        if (response.headers.get("content-type")?.includes("text/html")) {
          const result = await response.text();
          throw new QueryError(getQueryErrorCode(response.status), result);
        }
        const result = await response.json();
        throw result;
      } catch (error) {
        if (error instanceof QueryError) {
          throw error;
        } else {
          throw new QueryError("unknown");
        }
      }
    },
    queryOptions
  );
};

export default useHttpQuery;
